
import PropTypes from 'prop-types';
import {React,useState, useRef, useCallback, useEffect} from 'react';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
//
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import FileManagerPanel from './file-manager-panel';

import FileManagerFolderItem from './file-manager-folder-item';
import FileManagerActionSelected from './file-manager-action-selected';
import FileManagerShareDialog from './file-manager-share-dialog';
import FileManagerNewFolderDialog from './file-manager-new-folder-dialog';
import {useSettingsContext} from "../../components/settings";

import PixmeFileDetailsDrawer from "./pixmeFIleDetailsDrawer";
import PixmeFileItem from "./pixme-file-item";
import Lightbox, {useLightBox} from "../../components/lightbox";
import {useAxStore} from "../../store/axStore";

// import PixmeShowSelectedModal from "../../modal/pixShowSelectedModal";

// ----------------------------------------------------------------------

export default function PixmeFilesGridView({
  searchedValue,
  data,

  wholeCount,

  onDeleteItem,

}) {
  // const { selected, onSelectRow: onSelectItem, onSelectAllRows: onSelectAllItems } = table;

  const {
    selectedFiles,
    selectedFiles_insert,

    selectedFiles_insert_multiple,
    selectedFiles_delete,
    selectedFiles_deleteAll,
    selectedFiles_exists
  } = useAxStore()

  const containerRef = useRef(null);

  const [folderName, setFolderName] = useState('');

  const [inviteEmail, setInviteEmail] = useState('');
  const { t, onChangeLang } = useTranslation()


  // const lightbox = useBoolean(false);

  const newFolder = useBoolean();

  const upload = useBoolean();

  const files = useBoolean();
  const axStore=useAxStore();

  const folders = useBoolean();
  const settings = useSettingsContext();
   // const lightbox = useLightBox();
  const handleChangeInvite = useCallback((event) => {
    setInviteEmail(event.target.value);
  }, []);

  const handleChangeFolderName = useCallback((event) => {
    setFolderName(event.target.value);
  }, []);


  const [slides, setSlides] = useState([]);
  const [curindex, setIndex] = useState(-1);

  useEffect(() => {
    if (data && data.length > 0) {
      const formattedSlides = data.map(item => ({
          src: `${axStore.systemCurrentConfig.s3.s3_endpoint_preview}${item.filename}`
      }));
      setSlides(formattedSlides);

      console.log('formattedSlides:',formattedSlides)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {

      console.log('selected:',selectedFiles)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  const getGridTemplateColumns = () => {
    if (settings.themeStretch) {
      // If themeStretch is true, use these settings
      return {
        xs: 'repeat(2, 1fr)',
        sm: 'repeat(6, 1fr)',
        md: 'repeat(7, 1fr)',
        lg: 'repeat(9, 1fr)',
        xl: 'repeat(12, 1fr)',
      };
    }
      // If themeStretch is false, use different settings
      return {
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
        lg: 'repeat(4, 1fr)',
        xl: 'repeat(5, 1fr)',
      };

  };
  const details = useBoolean();
/*
  const handleCopy = useCallback(() => {
    enqueueSnackbar('Copied!');
    copy(file.url);
  }, [copy, enqueueSnackbar, file.url])
  */
  const [detailedFile,setDetailedFile]=useState(null)

  const onDelete = (id) => {
    // Update the state to remove the file with the given id
    // setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    console.log('going to delete file with id ',id);
  };

  const handleShowPreview= (idx) => {
    // lightbox.onOpen(preview.filename)
    // console.log('show preview',idx)
     setIndex(idx)

    // lightbox.onOpen(5)

  }
  const handleSelect = (fileId) => {
    if (selectedFiles_exists(fileId)) {
      selectedFiles_delete(fileId);
    } else {
      selectedFiles_insert(fileId);
    }
  };

  return (
    <>
      <PixmeFileDetailsDrawer
        item={detailedFile}
        favorited={upload.value} // upload is insadne but temporary
        onFavorite={upload.onToggle}
        // onCopyLink={handleCopy}
        open={details.value}
        onClose={details.onFalse}
        onDelete={() => {
          details.onFalse();
          onDelete(detailedFile.fileid);
        }}
      />
      {searchedValue&&<h1 style={{textAlign:"center",fontSize:"1.2em"}}>{t('search_result_for')} <span style={{color:"#ff2727"}}>{searchedValue}</span></h1>}
      <div style={{textAlign: "center",fontSize:"0.9em",marginBottom:"20px"}}>{`${t('showing')} ${data.filter((item) => item.type !== 'folder').length} ${t('files_gen')} ${t('of')} ${wholeCount}`}</div>
      <Grid
        container
        gap={2}
        sx={{justifyContent: "center"}}
      >

        { /* <Box ref={containerRef}>


        <FileManagerPanel
          title="Folders"
          subTitle={`${data.filter((item) => item.type === 'folder').length} folders`}
          onOpen={newFolder.onTrue}
          collapse={folders.value}
          onCollapse={folders.onToggle}
        />

        <Collapse in={!folders.value} unmountOnExit>
          <Box
            gap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(4, 1fr)',
            }}
          >
            {dataFiltered
              .filter((i) => i.type === 'folder')
              .map((folder) => (
                <FileManagerFolderItem
                  key={folder.id}
                  folder={folder}
                  selected={selected.includes(folder.id)}
                  onSelect={() => onSelectItem(folder.id)}
                  onDelete={() => onDeleteItem(folder.id)}
                  sx={{ maxWidth: 'auto' }}
                />
              ))}
          </Box>
        </Collapse>

        <Divider sx={{ my: 5, borderStyle: 'dashed' }} />
*/}


        {/* data
          .filter((i) => i.type !== 'folder')
          .map((file, index) => (
            <Grid key={file.fileid}>
              <PixmeFileItem
                file={file}
                onOpenDetail={() => {
                  setDetailedFile(file);
                  details.onTrue()
                }}
                selected={selectedFiles.includes(file.fileid)}
                onSelect={() => handleSelect(file.fileid)}
                onDelete={() => onDeleteItem(file.fileid)}
                onShowPreview={() => handleShowPreview(index)}
                sx={{width: '250px'}}
              />
            </Grid>
          )) */}

        {/* Object.entries(
          data
            .filter((i) => i.type !== 'folder')
            .reduce((acc, file) => {
              const groupKey = file.search_accuracy;
              if (!acc[groupKey]) {
                acc[groupKey] = [];
              }
              acc[groupKey].push(file);
              return acc;
            }, {})
        ).map(([accuracy, filese]) => {
          let heading = '';
          if (accuracy === '1') heading = 'Najrelevantnejšie výsledky vyhľadávania';
          else if (accuracy === '2') heading = 'Výsledky vyhľadávania a základe AI tagov';
          else if (accuracy === '3') heading = 'Výsledky vyhľadávania, ktoré vás môžu zaujímať';

          return (
            <div key={accuracy}>
              <h3 style={{textAlign:"center"}}>{heading}</h3>
              <Grid container spacing={2}>
                {filese.map((file, index) => (
                  <Grid key={file.fileid} item>
                    <PixmeFileItem
                      file={file}
                      onOpenDetail={() => {
                        setDetailedFile(file);
                        details.onTrue();
                      }}
                      selected={selectedFiles.includes(file.fileid)}
                      onSelect={() => handleSelect(file.fileid)}
                      onDelete={() => onDeleteItem(file.fileid)}
                      onShowPreview={() => handleShowPreview(index)}
                      sx={{ width: '250px' }}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          );
        }) */}

        {data
          .filter((i) => i.type !== 'folder')

          .map((file, index, array) => {
            // Determine if we need to add a heading
            const prevAccuracy = array[index - 1]?.search_accuracy;
            const currentAccuracy = file.search_accuracy;

            let heading = '';

            if (currentAccuracy !== prevAccuracy) {
              if (currentAccuracy === 1) heading = 'Most relevant';
              else if (currentAccuracy === 2) heading = 'AI search';
              else if (currentAccuracy === 3) heading = 'May be interesting for you';
            }

            return (
              <div key={file.fileid}>

                {/* heading && heading !== "" && (
                  <div style={{ width: "100%" }}>
                    <Grid xs={12}>
                      <h2>{heading}</h2>
                      <hr style={{ margin: '10px 0', borderTop: '2px solid #ccc' }} />
                    </Grid>
                  </div>
                ) */}



              <Grid >
                  <PixmeFileItem
                    file={file}
                    onOpenDetail={() => {
                      setDetailedFile(file);
                      details.onTrue();
                    }}
                    selected={selectedFiles.includes(file.fileid)}
                    onSelect={() => handleSelect(file.fileid)}
                    onDelete={() => onDeleteItem(file.fileid)}
                    onShowPreview={() => handleShowPreview(index)}
                    sx={{ width: '250px' }}
                  />
                </Grid>
              </div>
            );
          })}



      </Grid>


      {/*      </Box> */}


      <FileManagerNewFolderDialog open={upload.value} onClose={upload.onFalse}/>

      <FileManagerNewFolderDialog
        open={newFolder.value}
        onClose={newFolder.onFalse}
        title="New Folder"
        onCreate={() => {
          newFolder.onFalse();
          setFolderName('');
          console.info('CREATE NEW FOLDER', folderName);
        }}
        folderName={folderName}
        onChangeFolderName={handleChangeFolderName}
      />


      <Lightbox
        slides={slides}
        open={curindex >= 0}
        index={curindex}
        close={() => setIndex(-1)}
        disabledThumbnails
        /*
                index={lightbox.selected}
                disabledZoom={state.disabledZoom}
                disabledTotal={state.disabledTotal}
                disabledVideo={state.disabledVideo}
                disabledCaptions={state.disabledCaptions}
                disabledSlideshow={state.disabledSlideshow}
                disabledThumbnails={state.disabledThumbnails}
                disabledFullscreen={state.disabledFullscreen}
                */
      />

    </>
  );
}

PixmeFilesGridView.propTypes = {
  data: PropTypes.array,

  searchedValue: PropTypes.string,
  wholeCount: PropTypes.number,

  onDeleteItem: PropTypes.func,


};

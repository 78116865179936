import PropTypes from 'prop-types';
import * as Yup from 'yup';
import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import { useForm,useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import {_roles, _tags, USER_STATUS_OPTIONS} from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {RHFSelect, RHFTextField, RHFAutocomplete, RHFEditor, RHFUpload, RHFSwitch, RHFUploadAvatar} from 'src/components/hook-form';
import {AccordionDetails, Autocomplete, CircularProgress, Icon, LinearProgress, TextField, Tooltip} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tabs, {tabsClasses} from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import LinkIcon from '@mui/icons-material/Link';
import Zoom from "@mui/material/Zoom";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Card from "@mui/material/Card";
import {QueuePlayNext} from "@mui/icons-material";
import KeyIcon from '@mui/icons-material/Key';
import InputAdornment from "@mui/material/InputAdornment";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AccordionSummary from "@mui/material/AccordionSummary";
import Accordion from "@mui/material/Accordion";
import Fab from "@mui/material/Fab";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {useTranslation} from "react-i18next";
import axios from "../../utils/axios";
import {useAxStore} from "../../store/axStore";
import {useResponsive} from "../../hooks/use-responsive";
import {useAuthContext} from "../../auth/hooks";
import {useBoolean} from "../../hooks/use-boolean";
 import ConfirmExistingUserModal from "../../modal/confirm-existing-user-modal";
import {fData} from "../../utils/format-number";
import Label from "../../components/label";
import ChangeUserPasswModal from "../../modal/change-user-password-modal";

// ----------------------------------------------------------------------




// ----------------------------------------------------------------------

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k**i).toFixed(dm))  } ${  sizes[i]}`;
}
function ConvertToNiceDate(mysql_timestamp) {
  // console.log('ConvertToNiceDate working with ',mysql_timestamp);
  if((mysql_timestamp!=="0000-00-00 00:00:00")&&(mysql_timestamp!=="0000-00-00")) {
    const optionsDate = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    const optionsTime = {
      hour: 'numeric',
      minute: 'numeric',
    };
    const optionsDay = {
      weekday: 'long'
    };

    const dateFormatter = new Intl.DateTimeFormat('sk-SK', optionsDate);
    const timeFormatter = new Intl.DateTimeFormat('sk-SK', optionsTime);
    const dayFormatter = new Intl.DateTimeFormat('sk-SK', optionsDay);
    const timestamp_created = new Date(mysql_timestamp);
    // return dateFormatter.format(timestamp_created);

    // eslint-disable-next-line no-unreachable
    const niceDateObject={
      niceDate:dateFormatter.format(timestamp_created),
      niceTime: timeFormatter.format(timestamp_created),
      niceday: dayFormatter.format(timestamp_created)
    }
    return niceDateObject
  }return false;
} // returns an object with date, time, day_in_week_by_name
export default function UserQuickEditForm({ userIdToEdit, setUserIdToEdit, open, onClose,currentUserAsProp, runOnClose,isAxUser,depNameForUserToEdit,depIdForUserToEdit=0 }) {
  const mdUp = useResponsive('up', 'md');
  const isEmailForNewUserValidated = useBoolean(false);
  const isEmailForNewUserValidFormat = useBoolean(false);
  const isMyProfile = useBoolean(false);
  const { t, onChangeLang } = useTranslation()
  const [openExistingUserConfirmModal,setOpenExistingUserConfirmModal] = useState(false);
  const [ceumEmail,setCeumEmail] = useState('');
  const avatarFileIsUploading = useBoolean(false)

  // const [userAvatar,setCeumStatus] =  <ChangeUserPasswModal open={openChangeUserPasswModal} setOpen={setOpenExistingUserConfirmModal} email={ceumEmail} status={ceumStatus} depname={axStore.axCurrentDepartment.name} setConfExistUserModalResponse={setConfExistUserModalResponse}
  const [ceumStatus,setCeumStatus] = useState(0);
  const [ceumUserId,setCeumUserId] = useState(0);
  const [ceumCanEdit,setCeumCanEdit] = useState(0);
  const [confExistUserModalResponse,setConfExistUserModalResponse] = useState('');


  const [openChangeUserPasswModal,setOpenChangeUserPasswModal] = useState(false);



  const isValidatingEmail = useBoolean(false);
  const [paymentOverview,setPaymentOverview] = useState({})
  const [invoiceDates,setInvoiceDates] = useState([])
  const [currentUser,setCurrentUser] = useState({})
  const [usersLoading,setUsersLoading] = useState(false)
    const [currentUserAllLoadedData,setCurrentUserAllLoadedData] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false)
  const [isGettingCompanies, setIsGettingCompanies] = useState(false)
  const [linkingToCompany, setLinkingToCompany] = useState(false)
  const [companies, setCompanies] = useState([])
  const [dialogWidth, setDialogWidth] = useState("lg")
  const [labelForAutocompleteCompanies, setLabelForAutocompleteCompanies] = useState("Vyberte subjekt, ktorý je priradená tomuto departmentu")
  const axStore=useAxStore()
  const autocomplete=true;
  const [curUserSelectbox,setCurUserSelectbox]=useState('');
  const [curCompSelectbox,setCurCompSelectbox]=useState('');
  const [isSwitchingUser,setIsSwitchingUser]=useState(false);
  const [isSavingUserData,setIsSavingUserData]=useState(false);
  const [userNameSwitchingTo,setUserNameSwitchingTo]=useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);

  const user=axStore.axCurrentUser
  const currentYearForSel = new Date().getFullYear();

  // ******** generate tabs
  const generateTabs = () => {
    const defaultTabs = [
      {
        value: 'zakladne-nastavenie',
        label: t('user.basic_setting'),
        icon: <Iconify icon="tdesign:user-setting" width={24} />,
      },
      /* {
        value: 'kontakty',
        label: 'Kontakty',
        icon: <Iconify icon="teenyicons:contact-outline" width={24} />,
      }, */
{
        value: 'pripojenia',
        label: t('user.connections'),
        icon: <Iconify icon="mingcute:department-line" width={24} />,
      },

    ];




    // Add additional tabs for different type of users
    if (user.department_role===1||user.ax_role<2) {
      defaultTabs.push([
        {
          value: 'logy',
          label: 'Logy',
          icon: <Iconify icon="solar:battery-full-minimalistic-bold" width={24} />,
        },
        {
          value: 'posli-email',
          label: 'Pošli email',
          icon: <Iconify icon="material-symbols:mail" width={24} />,
        },{
          value: 'dokumenty',
          label: 'Dokumenty',
          icon: <Iconify icon="solar:documents-bold" width={24} />,
        }]
      );
    }


    return defaultTabs;
  };


  const TABS = generateTabs()

  // Generate an array of 10 options
  const yearOptions = Array.from({ length: 10+(currentYearForSel-2018) }, (_, index) => {
    const year = 2017 + index;
    return (
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    );
  });
  const [currentTab, setCurrentTab] = useState('zakladne-nastavenie');
  const handleCloseModal = () => {

    runOnClose()
    onClose()
    reset()
    isEmailForNewUserValidated.onFalse()
    isEmailForNewUserValidFormat.onFalse()

  }
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const onError = (errors, e) => {
    enqueueSnackbar("Chyba pri validácii, chck console",{ variant: `error`,autoHideDuration: 6000 });
    console.log('submit validation error:',errors, e)
  }

  const switchToDep = (value) => {  // value has id and web label
// console.log('switching to ',value.id)
    // alert(`vybrali ste ${value.label} a idem smerovat do ${value.id}`);
    setIsSwitchingUser(true)
    setUserNameSwitchingTo(value.label)

    setUserIdToEdit(parseInt(value.id,10))
    loadUserDataToEdit(parseInt(value.id,10))
  }

  useEffect(()=> {
     console.log("openExistingUserConfirmModal.value zmenene na ",openExistingUserConfirmModal.value)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[openExistingUserConfirmModal.value])

  const handleOptionSelectedDepClick = (event,value, reason) => {
     console.log("onchange dep from user modal edit")
    // console.log(event)
    // console.log(value)
     console.log(reason)
    if (reason === 'selectOption') switchToDep(value)
  };
  const handleOptionSelectedCompClick = (event,value, reason) => {
     console.log("onchange company")
    // console.log(event)
    // console.log(value)
    // console.log(reason)
    // if (reason === 'selectOption') switchToDep(value)
  };


 const NewUserSchema = Yup.object().shape({
    depname: Yup.string().when('userIdToEdit', {
       is: depId => depId > 0,
      then: schema=>schema.required('Názov departmentu je povinná položka'),
      otherwise: schema => schema.notRequired()
    }),
    /* description: Yup.string().when('userIdToEdit', {
       is: depId => depId !== "0",
      // is: true,
      then: schema=>schema.required('Popis departmentu je povinná položka'),
      otherwise: schema => schema.optional()
    }), */
    website_canonic_url: Yup.string().when('userIdToEdit', {
       is: depId => depId > 0,
      then: schema=>schema.url('Táto položka musí byť v tvare webovej adresy, napr. https://pixme.sk'),
      otherwise: schema => schema.notRequired()
    }),
   /* email_new: Yup.string().required('Email is required').email('Email must be a valid email address'), */
    /* website_canonic_url: Yup.string().when('userIdToEdit', {
      is: depId => depId > 0,
      then: Yup.string().url('Táto položka musí byť v tvare webovej adresy, napr. https://pixme.sk'),
      otherwise: Yup.string().nullable()
    }) */
    // name: Yup.string().required('Názov departmentu je povinná položka'),
    // website_canonic_url: Yup.string().url('Táto položká musí byť v tvare webovej adresy, napr. https://pixme.sk').nullable(),
    // description: Yup.string().required('Name is required'),
   /* email_new: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phoneNumber: Yup.string().required('Phone number is required'),
    address: Yup.string().required('Address is required'),
    country: Yup.string().required('Country is required'),
    company: Yup.string().required('Company is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    role: Yup.string().required('Role is required'), */
  });

  const defaultValues = useMemo(() => ({
      /* -------------- NOVY user  ----------------*/
      name_new: '',
      titul_new: '',
      titul_after_new: '',
      surname_new: '',
      gender_new: 0,
      user_status_new: 1,
      user_email_is_invoice_new: false,
      user_role_new: 2, // 1 - site admin - ja, 2 - admin, 3 soldier
      user_ax_role_new: 3, // 0 - hyperadmin - ja, 1 - superadmin, 2 dep_admin
      new_to_dep_id: 0,
      email_new: '',
      password_new: '',
      user_visible: !!(currentUser && currentUser.visible && currentUser.visible === "1"),
//         we_provide_website_for_this_department: !!(currentUser && currentUser.we_provide_website_for_this_department && currentUser.we_provide_website_for_this_department === "1"),
      user_name: currentUser && currentUser.name ? currentUser.name : '',
      user_surname: currentUser && currentUser.surname ? currentUser.surname : '',
      user_titul: currentUser && currentUser.title_before_name ? currentUser.title_before_name : '',
      user_titul_after_name: currentUser && currentUser.title_after_name ? currentUser.title_after_name : '',
      user_gender: currentUser && currentUser.gender ? currentUser.gender : 0,
      user_status: currentUser && currentUser.status ? currentUser.status : 0,
      user_ax_role: currentUser && currentUser.ax_role ? currentUser.ax_role : 3,

      avatarUrl: currentUser && currentUser.avatar_url ? axStore.systemCurrentConfig.s3.s3_endpoint_system+currentUser.avatar_url:''



      //        depname: '',
      // description: currentUser?.description || '',
      // concated_domains: currentUser?.domains_concated || '',
//         description: currentUser && currentUser.description ? currentUser.description : '',
//         concated_domains: currentUser && currentUser.domains_concated ? currentUser.domains_concated : '',

      /* -------------- ZAKLADNE NASTAVENIA  ----------------*/

    }),
// eslint-disable-next-line react-hooks/exhaustive-deps
    // [currentUser === null ? null : currentUser.id]
// eslint-disable-next-line react-hooks/exhaustive-deps
     [currentUser]
  );



  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    dirtyFields,
    setIsDirty,
    setValue,
    getValues,
    watch,
    control,
    formState: { isSubmitting, isDirty,touchedFields },
  } = methods;



// here load companies


// handleLinkToCompany
/*  function handleLinkToCompany(){
  console.log('clicked handleLinkToCompany - message from fc inside')
    setLinkingToCompany(true)

    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      const postValues = new FormData();
      postValues.append('action', 'link_company_to_dep');
      postValues.append('depid', currentUser.id);
      postValues.append('compid', selectedCompany.id);

      axios.post('https://api.administrix.pro/v1/post/',
        postValues,{
          headers: {'Authorization': `Bearer ${  jwt}`}
        } )
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
            if(data.success===1) {
              enqueueSnackbar("Firmu sme úspešne priradili.", {variant: `success`,autoHideDuration: 6000});
              loadUserDataToEdit(data.depid)
            }else {
              enqueueSnackbar("Niečo sa dosralo, chck console", {variant: `warning`,autoHideDuration: 6000});
              console.log(response.data)
            }
          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri priraďovaní firmy k departmentu: ${error.title}`,{ variant: `error` ,autoHideDuration: 6000});
          }
          setLinkingToCompany(false)
        }))
        .catch(error => {
           console.log("any linkingcompany fetch api error");
          // console.log(error);
          // console.log(error.title);
          // enqueueSnackbar(`Chyba získavaní údajov o webe: ${error.error.title}`,{ variant: `error` });
          // setErr(error);
          // setIsUploadedFilesQueueProcessing(false)
          // return (final_user)
          setLinkingToCompany(false)
        })
    }
  }
*/
// handleLinkToCompany

  // import { updateUserAvatar } from './actions';
  // const dispatch = useDispatch();
  // const { dispatch } = useAuthContext();
  /* const [state, dispatch] = useReducer(reducer, initialState);
  const updateUserAvatar = (avatarUrl) => ({
    type: 'UPDATE_USER_AVATAR',
    payload: {
      avatar_url: avatarUrl
    }
  });
*/

  function postUserDataToSaveToServer(user_data_to_save) {
    if (!isSavingUserData) {
      setIsSavingUserData(true)

      const jwt = localStorage.getItem('axjwt');
      if (jwt) {
        const postValues = new FormData();
        postValues.append('action', 'save_user_data');
        if (userIdToEdit === 0) {
          // console.log('genderValue:',genderValue)
          postValues.append('uid', "0");
          postValues.append('name_new', getValues('name_new'));
          postValues.append('user_status_new', getValues('user_status_new'));
          postValues.append('user_email_is_invoice_new', getValues('user_email_is_invoice_new'));
          postValues.append('surname_new', getValues('surname_new'));
          postValues.append('gender_new', getValues('gender_new'));
          postValues.append('titul_new', getValues('titul_new'));
          postValues.append('titul_after_new', getValues('titul_after_new'));
          postValues.append('email_new', getValues('email_new'));
          postValues.append('password_new', getValues('password_new'));
          postValues.append('user_role_new', getValues('user_role_new'));
          postValues.append('user_ax_role_new', getValues('user_ax_role_new'));
          postValues.append('new_to_dep_id', getValues('new_to_dep_id'));
        } else {
          postValues.append('uid', currentUser.id);

          Object.entries(user_data_to_save).forEach(([key, value]) => {
            postValues.append(key, value);
          });
        }
        postValues.append('isAxUser', isAxUser);
        // console.log(postValues)
        // return

        axios.post('https://api.administrix.pro/v1/post/',
          postValues, {
            headers: {'Authorization': `Bearer ${jwt}`}
          })
          .then((response => {
            if (response.data.data) {
              const {data} = response.data;
              const {meta} = response.data;
              if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

              if (userIdToEdit === 0) {
                if (data.newUserId) {
                  enqueueSnackbar("Výborne, vytvorili sme nového užívateľa", {variant: `success`, autoHideDuration: 6000});
                  setUserIdToEdit(data.newUserId)
                  // loadUserDataToEdit(userIdToEdit);
                  // loadUsersToAxStore()
                }
              } else if (data.success === 1) {
                enqueueSnackbar("Údaje o užívateľovi sme úspešne uložili.", {variant: `success`, autoHideDuration: 6000});
                reset(user_data_to_save)
                loadUserDataToEdit(currentUser.id);
                // loadUserDataToEdit(dep_data_to_save.depid)
              } else {
                enqueueSnackbar("Niečo sa dosralo, chck console", {variant: `warning`, autoHideDuration: 6000});
                console.log(response.data)
              }
            } else if (response.data.error) {
              const {error} = response.data;
              enqueueSnackbar(`Chyba pri ukladaní dát: ${error.title}`, {variant: `error`, autoHideDuration: 6000});
            }
            setIsSavingUserData(false)

          }))
          .catch(error => {
            console.log("any saving user data fetch api error");
            setIsSavingUserData(false)
          })
      }
    }
  }

const loadUserDataToEdit=(uid)=>{
  setCurrentUserAllLoadedData(false)
  if(uid>0){
    console.log("loadUserDataToEdit runned")
    setDialogWidth('xl')
    setIsLoading(true);
    let headers={}
    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      headers = {
        'Authorization': `Bearer ${jwt}`
      }
      const params = {
        'action': 'get_user_edit_data',
        'uid': uid,
        'depid': axStore.axCurrentDepartment.id,
        'isAxUser': isAxUser,
      }
      axios.get('https://api.administrix.pro/v1/get/', {
        headers,
        params
      })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;

            // console.log('data get_dep_edit_data response:')
          // console.log(response)
          if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
          data.created_at_nice=ConvertToNiceDate(data.created_at)
          // data.updated_at_nice=ConvertToNiceDate(data.updated_at)

//          setInvoiceDates([])
          setCurrentUser(data)

          setCurrentUserAllLoadedData(true)


          // setUserOldIdToEdit(parseInt(data.id,10))
            /*
            if(data.website_company){

              setSelectedCompany({"id":data.website_company.id,"label":data.website_company.name,"municipality":data.website_company.municipality,"street":data.website_company.street,"street_number":data.website_company.street_number})
              setLabelForAutocompleteCompanies("Tento department je priradený k subjektu")
            }else{
              console.log('dep NOT connected to company:')
              setSelectedCompany(null);
              setLabelForAutocompleteCompanies("Vyberte subjekt, ktorý je priradený k tomuto departmentu")
            }
*/
          // if(data.companies)setCompanies(data.companies)

          setDialogWidth('xl')
            setCurUserSelectbox({"id": data.id, "label": data.name})
          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri načítavaní dát o departmente: ${error.title}`,{ variant: `error`,autoHideDuration: 6000 });
          }
          setIsLoading(false);
          setIsSwitchingUser(false)
        }))
        .catch(error => {
          console.log("any get_user_edit_data fetch api error");
          console.log(error);
          setIsLoading(false);
          setIsSwitchingUser(false)
          setDialogWidth('xl')
        })
    }
  }else{console.log("loadUserDataToEdit skiped")}
}

const validateNewUserEmail=()=>{
    let headers={}
    const jwt = localStorage.getItem('axjwt');
    if(jwt) {
      isValidatingEmail.onTrue()
      headers = {
        'Authorization': `Bearer ${jwt}`
      }
      const params = {
        'action': 'validate_new_user_email',
        'email': getValues('email_new'),
        // 'depid': axStore.axCurrentDepartment.id,
        'depid': depIdForUserToEdit,
        'isAxUser': isAxUser,
      }
      axios.get('https://api.administrix.pro/v1/get/', {
        headers,
        params
      })
        .then((response => {
          if (response.data.data) {
            const {data} = response.data;
            const {meta} = response.data;
            if(data.status===0) {// user doesnt exists, we can continue
              isEmailForNewUserValidated.onTrue();
            }
            else
            if(data.status===1){ // user already exists in database
              isEmailForNewUserValidated.onFalse();
              setCeumEmail(getValues('email_new'))
              setCeumStatus(data.status)
              setCeumUserId(data.user)
              setCeumCanEdit(data.can_edit)
              setOpenExistingUserConfirmModal(true)
            }else
              if(data.status===2) { // user already exists in database and is connected to department
                // console.log('hirajemmadafaka')
                isEmailForNewUserValidated.onFalse();
                setCeumEmail(getValues('email_new'))
                setCeumStatus(data.status)
                setCeumUserId(data.user)
                setCeumCanEdit(data.can_edit)
                setOpenExistingUserConfirmModal(true)
              }
            // here more options - if 2, already exists and more info in data.user
          if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);
          }else if (response.data.error){
            const {error} = response.data;
            enqueueSnackbar(`Chyba pri validácii emailu: ${error.title}`,{ variant: `error`,autoHideDuration: 6000 });
          }
          isValidatingEmail.onFalse()
        }))
        .catch(error => {
          console.log("any validate_new_user_email fetch api error");
          console.log(error);
          isValidatingEmail.onFalse()
        })
    }
}

const watched_values = useWatch({ control,name:['email_new','user_gender'] });


useEffect(()=> {
  // console.log("currentUserAllLoadedData", currentUserAllLoadedData)
  if(currentUser&&Object.keys(currentUser).length>0) {
    console.log("reseting form with default values:", defaultValues);
    reset(defaultValues);


// Usage

    let avatarUrlTemp;
    if(currentUser?.avatar_version > 0){

       avatarUrlTemp=axStore.systemCurrentConfig.s3.s3_endpoint_system+currentUser.avatar_url;
    }else{
      switch (currentUser.gender){
        case(1):
           avatarUrlTemp=axStore.systemCurrentConfig.s3.s3_endpoint_system+axStore.systemDefault.avatarDefMale;
          break;
        case(2):
           avatarUrlTemp=axStore.systemCurrentConfig.s3.s3_endpoint_system+axStore.systemDefault.avatarDefFemale;
          break;
        default:
           // avatarUrlTemp=axStore.systemDefault.s3_system_url+axStore.systemDefault.avatarDef;
           avatarUrlTemp=`${axStore.systemCurrentConfig.s3.s3_endpoint_system}avatars/avatar-def${Math.floor(Math.random() * 8) + 1}.jpg`;
          break;
      }


    }

    setValue('avatarUrl', avatarUrlTemp)
    console.log('avatarUrl', avatarUrlTemp,currentUser)

  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[currentUser])


useEffect(()=> { // email new changed
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if(emailRegex.test(watched_values[0]))isEmailForNewUserValidFormat.onTrue();else isEmailForNewUserValidFormat.onFalse()
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[watched_values[0]])

useEffect(()=> { // user gender changed
  // console.log(watched_values[1])

  if(currentUser?.avatar_version === 0) {
    let avatarUrlTemp;
    switch (parseInt(watched_values[1],10)){
      case(1):
        avatarUrlTemp=axStore.systemCurrentConfig.s3.s3_endpoint_system+axStore.systemDefault.avatarDefMale;
        break;
      case(2):
        avatarUrlTemp=axStore.systemCurrentConfig.s3.s3_endpoint_system+axStore.systemDefault.avatarDefFemale;
        break;
      default:
        avatarUrlTemp=`${axStore.systemCurrentConfig.s3.s3_endpoint_system}avatars/avatar-def${Math.floor(Math.random() * 8) + 1}.jpg`;
        break;
    }

    setValue('avatarUrl', avatarUrlTemp)
    console.log('avatarUrl', avatarUrlTemp,currentUser)

  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
},[watched_values[1]])

/*

useEffect(()=> {
  console.log('useeffect selectedCompany')
  if(userIdToEdit===0)setDialogWidth('md');else setDialogWidth('xl')
  if(userIdToEdit) {
    console.log('company changed:', selectedCompany)
    if (currentUser.website_company && selectedCompany) {
      if (selectedCompany.id !== currentUser.website_company.id) console.log('vybrata nova firma'); else console.log('vybrata firma co uz bola priradena')
    } else console.log('vybrata nova firma')
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedCompany]);
*/
/* ------- NASTAV FORMULAR HODNOTAMI, KED SA NACITAJU Z DB -------- */
/*
useEffect(()=> {
  console.log('useeffect defaultValues')
    if(currentUser&&Object.keys(currentUser).length>0) {
       console.log("reseting form with default values:", defaultValues);
       reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[defaultValues,currentUser])
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[defaultValues])

*/
/*
  useEffect(()=> {
    console.log("currentUser useeffect = ",currentUser)
    if (!isGettingCompanies && companies.length === 0) {
      console.log("dep modal read comapnies")
      readAllAxCompanies()
    }

// eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentUser])

*/

  useEffect(()=> {
// console.log("Object.values(axStore.axDepartments):",Object.values(axStore.axDepartments))
    // toto sa spusti akonahle je nastavena hodnota pre currentUser, ale chcem to len pri prvom otvoreni, aby sa dotiahli data z API
    // console.log('useEffect open user quickedit')
    // console.log('userIdToEdit=', userIdToEdit)
    // console.log('previous depid userOldIdToEdit=', userOldIdToEdit)
    // console.log('depIdForUserToEdit=', depIdForUserToEdit)
    if(depIdForUserToEdit)setValue('new_to_dep_id',depIdForUserToEdit)


if(userIdToEdit!==0) {
  if (open) {
    if(userIdToEdit===user.id)isMyProfile.onTrue();else isMyProfile.onFalse()
    // console.log('open modal useeffect user quickedit')
    // console.log('change userIdToEdit useeffect')
    // console.log('previous depid userOldIdToEdit=', userOldIdToEdit)
    // console.log('userIdToEdit=', userIdToEdit)


      if(currentUser?.avatar_version === 0) {
        let avatarUrlTemp;
        switch (parseInt(watched_values[1],10)){
          case(1):
            avatarUrlTemp=axStore.systemCurrentConfig.s3.s3_endpoint_system+axStore.systemDefault.avatarDefMale;
            break;
          case(2):
            avatarUrlTemp=axStore.systemCurrentConfig.s3.s3_endpoint_system+axStore.systemDefault.avatarDefFemale;
            break;
          default:
            avatarUrlTemp=`${axStore.systemCurrentConfig.s3.s3_endpoint_system}avatars/avatar-def${Math.floor(Math.random() * 8) + 1}.jpg`;
            break;
        }

        setValue('avatarUrl', avatarUrlTemp)
        // console.log('avatarUrl', avatarUrlTemp,currentUser)
      }



    // reset(defaultValues);
 //   if ((!currentUserAllLoadedData)&&(!isLoading)&&(parseInt(userOldIdToEdit, 10) !== parseInt(userIdToEdit, 10))) loadUserDataToEdit(userIdToEdit);
    if (!isLoading) loadUserDataToEdit(userIdToEdit);

  }
}
  // },[currentUser]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open,userIdToEdit]);

  const onSubmit = handleSubmit(async (data, event) => {
    event.stopPropagation();
    // console.log('going to save user data',data)
    postUserDataToSaveToServer(data)
  });

  const submitUserSave = () => {
    // Your custom logic here
    const formData = getValues(); // Get current form data
    // console.log('going to save user data from submitUserSave',formData)
    postUserDataToSaveToServer(formData)

    // Force submit the form
    // submit();
  };



  const renderAddNewDepartment = (
    <>
       <ConfirmExistingUserModal open={openExistingUserConfirmModal} setOpen={setOpenExistingUserConfirmModal} email={ceumEmail} status={ceumStatus} depname={depNameForUserToEdit} setConfExistUserModalResponse={setConfExistUserModalResponse}
       canEdit={ceumCanEdit} setUserIdToEdit={setUserIdToEdit} userid={ceumUserId} depid={depIdForUserToEdit}
       />


      {isAxUser?(
    <DialogTitle>{t('user.create_new_admin_for_dep')} {depNameForUserToEdit}</DialogTitle>
        ):(<DialogTitle>{t('user.create_new_user_for_dep')} {depNameForUserToEdit}</DialogTitle>)}
    <DialogContent sx={{padding:5}}>


      <Grid xs={12} container sx={{marginTop:2}}spacing={2}>
        <Grid xs={12} >
          <RHFTextField name="email_new" label={t('user.user_email')} disabled={isEmailForNewUserValidated.value}/>
        </Grid>
        {isEmailForNewUserValidated.value&&
          <>
        <Grid xs={12} >
          <RHFSelect name="user_status_new" label={t('user.account_status')}>
            <MenuItem value="0" key="0">{t('user.account_disabled')}</MenuItem>
            <MenuItem value="1" key="1">{t('user.account_active')}</MenuItem>
            {/* <MenuItem value="2" key="2">{t('user.account_failed_logins_disabled')}</MenuItem>
            <MenuItem value="3" key="3">{t('user.account_banned_different_reasons')}</MenuItem>
            <MenuItem value="20" key="20">{t('user.account_pending_new_user')}</MenuItem> */}
          </RHFSelect>
        </Grid>
        {(depIdForUserToEdit===0)&&<Grid xs={12} >
          <RHFTextField name="new_to_dep_id" label="Priradiť k departmentu - zadajte ID [sem neskor spravit selectbox (dep ax a pixme)]"/>
          {/*
          <Autocomplete
            disablePortal
            fullWidth
            value={newAddToDepValue}
            options={newDepValues}
            onChange={(event, newDepValue) => {
              setNewAddToDepValue(newDepValue);
            }}
            isOptionEqualToValue={(option, value) => parseInt(option.depId, 10) === parseInt(value.depId, 10)}
            inputValue={inputNewDepValue}
            onInputChange={(event, newInputGenderValue) => {
              setInputNewDepValue(newInputGenderValue);
            }}
            getOptionLabel={(option) => option.depName}
            renderInput={(params) => <TextField {...params} label="Priradiť k departmentu" margin="none" />}
            renderOption={(props, option) => (
              <li {...props} key={option.depName}>
                {option.depName}
              </li>
            )}
          /> */}
        </Grid>}


        {(user.ax_role<2)&&
          <Grid xs={12}>
              <RHFSelect name="user_ax_role_new" label={t('user.admin_rights_in_ax_pixme')}>
                {(user.ax_role<1)&&<MenuItem value="1" key="1">Ultraadmin</MenuItem>}
                <MenuItem value="2" key="2">{t('roles.user')}</MenuItem>

                {Object.keys(axStore.axRoles).map(roleKey => {
                  const role = axStore.axRoles[roleKey];
                  if (role.role_level > 0 && (((role.role_level === 1) && (user.ax_role < 1)) || (role.role_level > 1))) {
                    return (
                      <MenuItem value={role.role_level} key={role.role_level} title={t(role.role_desc)}>
                        {t(role.role_name)}
                      </MenuItem>
                    );
                  }
                  return null;
                })}


              </RHFSelect>
          </Grid>}
          <Grid xs={12}>
            <RHFSelect name="user_role_new" label={t('user.admin_rights_in_department')}>
              { /*
              <MenuItem value="1" key="1" title="Hlavný administrátor departmentu - spravuje department, užívatelov a obsah.">Hlavný administrátor</MenuItem>
              <MenuItem value="2" key="2" title="Spravuje obsah - vytvára a môže mazať obsah aj iných užívateľov">Správca obsahu</MenuItem>
              <MenuItem value="3" key="3" title="Spravuje len svoj obsah">Editor</MenuItem>
              <MenuItem value="4" key="4" title="Obsah môže len prezerať">Uživateľ</MenuItem>
*/ }
              {Object.keys(axStore.departmentRoles).map(roleKey => {
                const role = axStore.departmentRoles[roleKey];
                return (
                  <MenuItem value={role.role_level} key={role.role_level} title={t(role.role_desc)}>
                    {t(role.role_name)}
                  </MenuItem>
                );
              })}

            </RHFSelect>


          </Grid>

            <Grid xs={12} sm={2}>
              <RHFSelect name="gender_new" label={t('user.gender')}>
                <MenuItem value="0" key="0">{t('user.gender_dont_select')}</MenuItem>
                <MenuItem value="1" key="1">{t('user.gender_male')}</MenuItem>
                <MenuItem value="2" key="2">{t('user.female')}</MenuItem>
              </RHFSelect>
            </Grid>
            <Grid xs={12} sm={2}>
          <RHFTextField name="titul_new" label={t('user.title')}/>
          </Grid>
        <Grid xs={12} sm={3}>
          <RHFTextField name="name_new" label={t('user.name')}/>
        </Grid>
        <Grid xs={12} sm={3}>
          <RHFTextField name="surname_new" label={t('user.surname')}/>
        </Grid>

        <Grid xs={12} sm={2}>
          <RHFTextField name="titul_after_new" label={t('user.title_after_name')}/>
        </Grid>

        {(user.ax_role<2)&&
        <Grid xs={12}  sm={6}>
          <RHFSwitch name="user_email_is_invoice_new" label={t('user.set_email_for_invoice_receiving')}/>
        </Grid>
        }

        <Grid xs={12}>
          <RHFTextField name="password_new" label={t('user.password0')}/>
          </Grid>
</>}

      </Grid>


    </DialogContent>

      </>
  )
  const getOptionLabel = (option) => option.label;
  const getOptionLabelCompanies = (option) => (`${option.label}, ${option.street} ${option.street_number}, ${option.municipality}. ID:${option.id}`);
  const datetimeSlovakFormatOptions = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' };

  const handleCompaniesAutoselectOnBlur = () => {

    if(userIdToEdit!==0&&currentUser.website_company&&!selectedCompany) {
      setSelectedCompany({"id":currentUser.website_company.id,"label":currentUser.website_company.name,"municipality":currentUser.website_company.municipality,"street":currentUser.website_company.street,"street_number":currentUser.website_company.street_number})
    }

  }

  const handleDropAvatar = useCallback(
    (acceptedFiles) => {

/*
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      }); */




      if(!avatarFileIsUploading.value) {
        avatarFileIsUploading.onTrue()
        const jwt = localStorage.getItem('axjwt');
        if (jwt) {

          const file = acceptedFiles[0];
          const postValues = new FormData();
          postValues.append('file_to_set_avatar', file);
          postValues.append('uid', currentUser.id); // user id to whom are we going to change the passw
          postValues.append('action', 'upload_avatar'); // user id to whom are we going to change the passw


          axios.post('https://api.administrix.pro/v1/post/',
            postValues, {
              headers: {'Authorization': `Bearer ${jwt}`}
            })
            .then((response => {
              if (response.data.data) {
                const {data} = response.data;
                const {meta} = response.data;
                if (meta.jwt) localStorage.setItem('axjwt', meta.jwt);

                if (data.status === 1) {

                  enqueueSnackbar(t('user.photo_change_succ'), {variant: `success`, autoHideDuration: 6000});
                  setValue('avatarUrl',axStore.systemCurrentConfig.s3.s3_endpoint_system+data.avatar_url )
                  if(parseInt(axStore.axCurrentUser.id,10)===parseInt(currentUser.id, 10))
                    axStore.setCurrentUser({ ...axStore.axCurrentUser, avatar_url: data.avatar_url })
//                  dispatch(updateUserAvatar(data.avatar_url));
                } else {
                  enqueueSnackbar(t('user.photo_change_failed'), {variant: `error`, autoHideDuration: 6000});

                }


              } else if (response.data.error) {
//            const {error} = response.data;
                enqueueSnackbar(`${t('user.photo_change_error')} ${response.data.error.title}`, {variant: `error`, autoHideDuration: 6000});

              }

            }))
            .catch(error => {
              console.log("any changing avatar  fetch api error",error);

            })

        }
        avatarFileIsUploading.onFalse()
      }















/*
      if (file) {
        setValue('avatarUrl', newFile, { shouldValidate: true });
      } */
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue,currentUser]
  );

  const renderEditDepartment = (
    (currentUser&&userIdToEdit!==0)?(<>

      <ChangeUserPasswModal open={openChangeUserPasswModal} setOpen={setOpenChangeUserPasswModal}  userid={currentUser.id}/>

      <DialogTitle>{isMyProfile.value? t('user.my_account'):t('user.users_account')}</DialogTitle>
      <DialogContent>

      <Grid container spacing={2} sx={{paddingTop:1}}>
    <Grid xs={12} sm={7} md={8} lg={9} xl={10} >
      {(!isMyProfile.value&&Object.keys(axStore.axDepartments).length > 1) && (<Autocomplete
        autoComplete={autocomplete}
        value={curUserSelectbox !== '' ? curUserSelectbox : null}
        disablePortal
        id="depSelectbox"
        size="small"
        isOptionEqualToValue={(option, value) => parseInt(option.id, 10) === parseInt(value.id, 10)}

        getOptionLabel={getOptionLabel}
        options={Object.values(axStore.axDepartments)}
        onChange={handleOptionSelectedDepClick}
        renderInput={(params) => <TextField {...params} label={t('user.select_user_to_edit')}/>}
      />)}
    </Grid>
        <Grid xs={12} sm={5}  md={4} lg={3} xl={2} >
          {!isMyProfile.value&&<Button
            fullWidth
            color="success"
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
            onClick={()=>{setCurrentUserAllLoadedData(false);setCurrentUser(null);setUserIdToEdit(0)}}
          >
            {t('dep.add_new_user')}
          </Button>}
        </Grid>
    <Grid xs={12} lg={3}>
      <Paper elevation={4} sx={{margin: 2, padding: 2, fontSize: "0.7em"}}>



        <Grid container>

        {isLoading && (<Grid><Typography align="center" sx={{fontSize: "0.6em"}}>{t('user.loading_user_data')} {userNameSwitchingTo}...</Typography><LinearProgress/></Grid>)}
        {currentUserAllLoadedData && (<>
          <Grid sm={12} sx={{textAlign:"center"}}>

            <Label
              color={
                (currentUser.status === 1 && 'success') ||
                (currentUser.status === 0 && 'error') ||
                (currentUser.status === 2 && 'error') ||
                (currentUser.status === 3 && 'error') ||
                'warning'
              }
              sx={{ position: 'relative',  top:-5,textTransform: 'none' }}
            >
              {
                t(axStore.userStatuses[currentUser.status].status_name)

              }
            </Label>

            <RHFUploadAvatar
              name="avatarUrl"
              caption={t('user.change_photo')}
              maxSize={3145728}
              onDrop={handleDropAvatar}
              rounded
              />
            <Tooltip TransitionComponent={Zoom} title={`${t('user.user_was_created_by')} ${currentUser.created_by_name}, ${currentUser.created_at_nice.niceDate} o ${currentUser.created_at_nice.niceTime}`}>
            <Typography variant="h6" gutterBottom >
              {`${currentUser?.title_before_name} ${currentUser?.name} ${currentUser?.surname}${currentUser.title_after_name ? ', ' : ''} ${currentUser.title_after_name}`}
            </Typography>
            </Tooltip>

            {user.ax_role>0&&
            <Tooltip title={`${t(axStore.departmentRoles[currentUser.department_role].role_name)} in ${axStore.axCurrentDepartment.name}`}>
            <Typography
              variant="caption"
              sx={{
                mx: 'auto',
                mb:"18px",
                display: 'block',
                textAlign: 'center',
                color: 'text.disabled',
              }}
            >
              {
                t(axStore.departmentRoles[currentUser.department_role].role_name)

              }
            </Typography>
            </Tooltip>
            }

          </Grid>
        <Grid sm={12} md={6} lg={12} >

          {user.department_role<2||user.ax_role<2&&<Typography variant="body2" gutterBottom>
            Id: <strong>{currentUser.id}</strong>
          </Typography>}


<Tooltip title={`${t('user.system_admin_rights')}: ${t(axStore.axRoles[currentUser.ax_role].role_name)}`}>
          <Typography variant="body2" gutterBottom sx={{textAlign:"center"}}>
             {currentUser.email}
          </Typography>
</Tooltip>

          <Stack xs={12} spacing={1} sx={{justifyContent:"center",display:"flex",mt:3}}>

          <Button variant="contained" color="warning" endIcon={<KeyIcon />} onClick={()=>setOpenChangeUserPasswModal(true)}>
            {t('user.change_password')}
          </Button>

            {user.ax_role<2&&
          <Button variant="contained"  variant="soft" color="error" endIcon={<DeleteForeverIcon />}>
            {t('user.delete_user')}
          </Button>}


          </Stack>

        </Grid>

        </>)}


</Grid>
      </Paper>
    </Grid>


    <Grid xs={12} lg={9}>

      <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>

          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            aria-label={t('user.user_settings')}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label}/>
            ))}
          </Tabs>
        </Box>
        {currentTab === 'pripojenia' &&
          <Grid xs={12} container sx={{marginTop:2,top: 0, maxHeight: "60vh", overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}} spacing={2}>
            <Grid xs={12}>
            <Typography variant="h6" gutterBottom>
              {t('user.departments_which')} {isMyProfile.value? t('user.am_i_able'):t('user.user_is_able')} {t('user.to_administrate')}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Stack spacing={2}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {user.ax_role<2&&<TableCell>ID</TableCell>}
                      <TableCell >{t('dep.department_name')}</TableCell>
                      <TableCell align="center">{t('dep.role')}</TableCell>
                      <TableCell >{t('user.default_page')}</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentUser?.assigned_departments?.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        {user.ax_role<2&&<TableCell >{row.id}</TableCell>}
                        <TableCell component="th" scope="row">
                          {row.department_name}
                        </TableCell>
                        <TableCell align="center">
                          { t(axStore.departmentRoles[row.department_role].role_name)}
                          </TableCell>
                        <TableCell >{row.default_page}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>


            </Stack>

          </Grid>
          </Grid>
        }
        {currentTab === 'zakladne-nastavenie' &&

          <Grid xs={12} container sx={{marginTop:2,top: 0, maxHeight: "60vh", overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}} spacing={2}>
            {(user.ax_role<2||user.department_role===1)&&

              <Grid xs={12}>
              <RHFSelect name="user_status" label={t('user.account_status')}>
              <MenuItem value="0" key="0">{t('user.account_disabled')}</MenuItem>
              <MenuItem value="1" key="1">{t('user.account_active')}</MenuItem>
              <MenuItem value="2" key="2">{t('user.account_failed_logins_disabled')}</MenuItem>
              <MenuItem value="3" key="3">{t('user.account_banned_different_reasons')}</MenuItem>
              <MenuItem value="20" key="20">{t('user.account_pending_new_user')}</MenuItem>
              </RHFSelect>
            </Grid>

                }
              {(user.ax_role<2)&&

              <Grid xs={6}>
            <RHFSelect name="user_ax_role" label="Práva v administračnom rozhraní">
              {(user.ax_role<1)&&<MenuItem value="1" key="1">Ultraadmin</MenuItem>}
              <MenuItem value="2" key="2">Užívateľ</MenuItem>
            </RHFSelect>
          </Grid>

            }

            <Grid xs={12} sm={2}>
              <RHFSelect name="user_gender" label="Pohlavie">
                <MenuItem value="0" key="0">Nenastavujem</MenuItem>
                <MenuItem value="1" key="1">Muž</MenuItem>
                <MenuItem value="2" key="2">Žena</MenuItem>
              </RHFSelect>
            </Grid>
            <Grid xs={12} sm={2}>
              <RHFTextField name="user_titul" label="Titul"/>
            </Grid>
            <Grid xs={12} sm={3}>
              <RHFTextField name="user_name" label="Meno užívateľa"/>
            </Grid>
            <Grid xs={12} sm={3}>
              <RHFTextField name="user_surname" label="Priezvisko užívateľa"/>
            </Grid>

            <Grid xs={12} sm={2}>
              <RHFTextField name="user_titul_after_name" label="Titul za menom"/>
            </Grid>




        </Grid>}


        {currentTab === 'privilegia' &&
        <Grid xs={12} sx={{top: 0, height: "60vh", overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}}>
          <Stack spacing={2}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('dep.department_name')}</TableCell>
                    <TableCell align="right">Role</TableCell>
                    <TableCell align="right">Default page</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentUser.assigned_departments.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.department_name}
                      </TableCell>
                      <TableCell align="right">{row.department_role}</TableCell>
                      <TableCell align="right">{row.default_page}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Grid>}

        {currentTab === 'logy' &&
        <Grid xs={12} sx={{top: 0, height: "60vh", overflow: 'auto', paddingTop: "20px", paddingLeft: "20px"}}>
          <Stack spacing={2}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Dátum a čas</TableCell>
                    <TableCell>Názov departmentu</TableCell>
                    <TableCell align="right">Popis</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentUser.activity_log.map((row) => (
                    <TableRow
                      key={row.created_at}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.created_at}
                      </TableCell>
                      <TableCell align="right">{row.department_name}</TableCell>
                      <TableCell align="right">{row.desc}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Grid>}



      </Box>
    </Grid>
    <Grid>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      />
    </Grid>
  </Grid>
      </DialogContent>
  </>):null

      )

  return (
    <Dialog
      fullWidth
      maxWidth={dialogWidth}
      open={open}
      onClose={handleCloseModal}
    >
      <FormProvider

        methods={methods}
/*        onSubmit={handleSubmit(onSubmit, onError)} */

      >
          {(userIdToEdit!==0)?renderEditDepartment:renderAddNewDepartment  }
          {/* console.log("cely komponente render, userIdToEdit:",userIdToEdit,typeof userIdToEdit) */}
          {/* renderEditDepartment */}

        <DialogActions>
          {(!isEmailForNewUserValidated.value && isEmailForNewUserValidFormat.value && userIdToEdit===0) && <LoadingButton
            /* type="submit" */
            onClick={validateNewUserEmail}
            variant="contained"
            size="large"
            loading={isSubmitting}
            startIcon={<PersonSearchIcon/>}
            sx={{ml: 2, backgroundColor: '#047505', color: '#fff'}}
          >
            {t('user.continue')}
          </LoadingButton>
          }

          {(isDirty && isEmailForNewUserValidated.value && userIdToEdit===0) && <LoadingButton
            /* type="submit" */
            onClick={submitUserSave}
            variant="contained"
            size="large"
            loading={isSubmitting}
            startIcon={<QueuePlayNext/>}
            sx={{ml: 2, backgroundColor: '#047505', color: '#fff'}}
          >
            {t('user.create_new_user')}
          </LoadingButton>
          }

          {(isDirty&&userIdToEdit>0) && <LoadingButton
            /* type="submit" */
            onClick={submitUserSave}
            variant="contained"
            size="large"
            loading={isSubmitting}
            startIcon={<SaveIcon/>}
            sx={{ml: 2, backgroundColor: '#cc0d0d', color: '#fff'}}
          >
            {t('save_changes')}
          </LoadingButton>
          }

          <Button variant="outlined" onClick={handleCloseModal}>
            {t('close')}
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  userIdToEdit: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  isAxUser: PropTypes.bool,


  setUserIdToEdit:PropTypes.func,
  runOnClose:PropTypes.func,

  depIdForUserToEdit:PropTypes.number,
  depNameForUserToEdit:PropTypes.string,
  currentUserAsProp: PropTypes.object,
};
